import { dsl } from '@wix/yoshi-serverless/wrap';

export const fetch = dsl({
          functionName: 'fetch',
          fileName: 'api/feed/feed.api',
        });

export const create = dsl({
          functionName: 'create',
          fileName: 'api/feed/feed.api',
        });

export const update = dsl({
          functionName: 'update',
          fileName: 'api/feed/feed.api',
        });

export const remove = dsl({
          functionName: 'remove',
          fileName: 'api/feed/feed.api',
        });

export const get = dsl({
          functionName: 'get',
          fileName: 'api/feed/feed.api',
        });

export const search = dsl({
          functionName: 'search',
          fileName: 'api/feed/feed.api',
        });

export const react = dsl({
          functionName: 'react',
          fileName: 'api/feed/feed.api',
        });

export const unreact = dsl({
          functionName: 'unreact',
          fileName: 'api/feed/feed.api',
        });

export const subscribe = dsl({
          functionName: 'subscribe',
          fileName: 'api/feed/feed.api',
        });

export const unsubscribe = dsl({
          functionName: 'unsubscribe',
          fileName: 'api/feed/feed.api',
        });

export const pin = dsl({
          functionName: 'pin',
          fileName: 'api/feed/feed.api',
        });

export const unpin = dsl({
          functionName: 'unpin',
          fileName: 'api/feed/feed.api',
        });